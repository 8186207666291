.mask {
	background-color: $white;
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 100000;
	top: 0; }

#loader {
	background: url('../img/loading.gif') center center no-repeat;
	height: 40px;
	width: 128px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%); }
