.from-top {
	top: -50px;
	opacity: 0; }


.from-bottom {
	bottom: -50px;
	opacity: 0; }


.from-left {
	left: -15px;
	opacity: 0; }


.from-right {
	right: -15px;
	opacity: 0; }


.fade-in {
	opacity: 0;
	position: relative;
	padding-left: 0px;
	right: 0px; }

