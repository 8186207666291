.well {
	padding: 20px 15px;
	border: none;
	border-radius: 0;
	box-shadow: none;
	background-color: $grey;
	color: $white;
	font-size: $font-size-base * 0.75;
	margin-top: 20px;

	p {
		margin-bottom: 0; } }
