.footer {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	background-color: $dark-grey;
	border-top: 5px solid $red;
	color: $footer-link-color;
	padding-top: 35px;

	.info {
		font-family: arial;
		min-height: $footer-height - 35px;
		.col-sm-6 {
			text-align: center;
			@media (min-width: $screen-sm-min) {
				&:first-child {
					text-align: left; }

				ul {
					margin-bottom: 10px;

					li {
						background-image: none; } }

				&:last-child {
					text-align: right; } }
			ul {
				padding-left: 0; } }

		a {
			color: $white;
			&:hover {
				color: darken($link-color, 15%); } } } }

.subfooter {
	background-color: $black;
	width: 100%;
	min-height: $subfooter-height;

	.copyright {
		padding-top: 25px;
		text-align: center;

		@media (min-width: $screen-sm-min) {
			text-align: right; } } }


.h2-clientes {
	color: $red; }

hr {
	border-top: 1px dotted; }

.footer-clientes {
	padding: 0px 60px 0px 60px;
	top: 30px;
	margin-bottom: 60px; }
