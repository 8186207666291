.btn-primary {
	@include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
	font-weight: 600;
	border-radius: 10px;

	img {
		margin: -5px 0 0 10px; } }

.btn-success {
	@include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
	font-weight: 600;
	border-radius: 10px;
	background-image: url('../img/chevron-transparent.png');
	background-repeat: no-repeat;
	background-position: 100px center;
	padding: 10px 40px; }
