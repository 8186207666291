/* bullets */

.bullets {
	text-align: left;
	margin-top: 20px;

	li {
		position: relative;
		float: left;

		.rombo {
			width: 10px;
			height: 10px;
			background: $red;
			-webkit-transform: rotate(45deg);
			-moz-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			-o-transform: rotate(45deg);
			transform: rotate(45deg);
			float: left;
			position: absolute;
			left: 0;
			top: 8px; }

		p {
			padding-left: 30px !important; } } }



