.navbar {
	@media (min-width: $screen-sm-min) {
		margin-bottom: 0; } }

.navbar-default {
	background-color: $bg-nabvar;
	border-radius: 0;
	border: none;
	min-height: $navbar-min-height; }

.navbar-brand {
	height: inherit; }

.slogan {
	margin: 0 auto;
	width: 280px;
	text-align: center;
	@media (min-width: 630px) {
		width: 564px; }
	@media (min-width: $screen-sm-min) {
		margin-top: 30px;
		width: 20%;
		text-align: left; }
	@media (min-width: $screen-md-min) {
		width: 33%; }
	@media (min-width: 1000px) {
		width: 25%; }
	@media (min-width: $screen-lg-min) {
		width: 22%; }
	@media (min-width: 1400px) {
		margin-top: 35px;
		width: 33%;
		display: table-cell;
		vertical-align: bottom;
		line-height: 1; }

	.img-responsive {
		@media (min-width: $screen-sm-min) {
			display: inline;
			vertical-align: bottom; }
		@media (min-width: 1400px) {
			margin-left: 10px; } } }

.navbar-collapse {
	border: none;
	@media (min-width: $screen-sm-min) {
		margin-top: 34px; } }

.navbar-toggle {
	margin-top: 34px;
	border: none;

	.icon-bar {
		height: 5px;
		border-radius: 2px;
		background-color: $red !important; } }

.navbar-default {
	.navbar-nav {
		> li {
			background-image: none;

			> a {
				font-weight: bold;
				font-size: $font-size-base * .8;
				&:hover, &:focus {
					color: $red; }
				@media (min-width: $screen-lg-min) {
					padding: 15px 15px;
 } }					//padding: 15px 35px

			a.social {
				@media (min-width: $screen-sm-min) {
					padding: 0;
					margin-top: 5px; }

				@media (min-width: $screen-lg-min) {
					margin-left: 75px; } } }

		> .open > a,
		> .open > a:hover,
		> .open > a:focus,
		> .active > a,
		> .active > a:hover,
		> .active > a:focus {
			background-color: $white;
			color: $red; } } }

.dropdown-menu {
	padding: 0;
	border-radius: 4px;
	//background-color: transparent
	border: none;
	min-width: 120px;

	> li {
		background-image: none;
		border-radius: 4px;
		> a {
			font-weight: bold;
			font-size: $font-size-base * .75;
			background-image: url('../img/icon-diamond.png') !important;
			background-position: 10px 10px;
			background-repeat: no-repeat !important;

			@media (min-width: $screen-sm-min) {
				background-position: 5px 5px; }

			&:hover, &:focus {
				background-color: $red !important;
				background-position: 10px 0px;
				color: $white !important;
				border-radius: 4px !important;

				@media (min-width: $screen-sm-min) {
					background-position: 5px -4px; } } } } }
