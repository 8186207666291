/* novedades */
.novedades {
	padding: 0 20px;

	.row {
		[class*=col-] {
			padding: 5px !important; } }

	.select-orden {
		border: 1px solid #e1e1e1;
		border-radius: 3px;
		background-color: #fff;
		color: #666;
		box-shadow: none;
		padding: 5px;
		margin-right: 15px;
 }		//-webkit-appearance: none

	.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
		z-index: 3;
		color: #fff;
		background-color: $red;
		border-color: $red;
		cursor: default; }

	.pagination > li > a, .pagination > li > span {
		position: relative;
		float: left;
		padding: 6px 12px;
		line-height: 1.428571429;
		text-decoration: none;
		color: #222;
		background-color: #fff;
		border: 1px solid #ddd;
		margin-left: -1px; }



	.detalle-noticia {
		img {
			width: 100%; }

		h1 {
			font-size: 20px; }

		h4 {
			font-size: 14px; }

		p {
			font-size: 12px; } }

	.sidebar {
		h4 {
			background-color: #363636;
			border-top: 4px solid $red;
			color: #fff;
			padding: 10px;
			margin-top: 0; }

		a {
			color: #363636;

			&:hover {
				.item {
					opacity: .8; } } }
		.item {
			width: 100%;
			float: left;
			padding: 20px 0;
			border-bottom: 1px solid #d1d1d1;

			.img {
				background-size: cover;
				padding-top: 25% !important;
				background-position: center center; }

			h5 {
				padding: 0;
				margin: 0;
				line-height: 20px; }

			h6 {
				padding: 0;
				margin: 0;
				line-height: 15px;
				font-size: 11px; }


			.descripcion {
				padding: 0;
				margin: 0;
				line-height: 15px;
				font-size: 11px;
				text-align: left !important;


				p, h1, h2, h3, h4, h5, h6 {
					font-size: 11px;
					font-weight: normal;
					text-align: left !important; } } } } }


