.carousel {
	// @media (min-width: $screen-sm-min)
	//	margin-bottom: 65px

	.carousel-caption {
		position: absolute;
		display: table-cell;
		text-shadow: none;
		top: 0;
		text-align: left;

		@media (min-width: 630px) {
			background-color: $red;
			right: 0;
			bottom: 0;
			left: 64%;
			height: 100%; }

		@media (min-width: $screen-sm-min) {
			left: 65%; }

		&:before {
			@media (min-width: 630px) {
				content: '';
				border-radius: 5%;
				position: absolute;
				-webkit-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				transform: rotate(45deg);
				background-color: $red;
				top: 0;
				width: 125%;
				height: 100%;
				right: 16%; }

			// @media (width: 768px)
			// 	content: ''
			// 	width: 85%
			// 	height: 90%
			// 	right: 48%

			@media (min-width: $screen-sm-min) {
				content: '';
				width: 85%;
				height: 100%;
				right: 40%; } }

		.caption-inner {
			position: absolute;
			text-align: right;
			right: 0;
			width: 60%;

			&.caption-big {
				width: 90%;

				p {
					img {
						margin-left: -15px;
						@media (min-width: 0px) and (max-width : 767px) {
							margin-left: 0px; } } } }

			@media (min-width: 630px) {
				text-align: left;
				right: inherit;
				left: 0; }

			p, h1, h2, h3 {
				background-color: $red;
				display: inline;
				float: right;
				clear: both;
				margin: 2px 0;
				padding: 5px;
				position: relative;
				border-radius: 5px;

				@media (min-width: 630px) {
					float: left; } }

			@media (min-width: 630px) {
				display: block;
				right: inherit;
				left: 0;
				width: 90%;
				top: 50%;
				-webkit-transform: translate(0,-50%);
				-ms-transform: translate(0,-50%);
				transform: translate(0,-50%);
				right: 0;

				h1 {
					font-size: 30px; }
				h2 {
					font-size: 16px; } }

			@media (min-width: $screen-sm-min) {
				display: block;
				right: inherit;
				width: 65%;

				p, h1, h2, h3 {
					background-color: transparent;
					display: block;
					float: none; }

				p {
					font-weight: 600; }

				h1, h2 {
					margin: 0; }

				h1 {
					font-size: 36px; }

				h2 {
					font-size: 26px; } }

			@media (min-width: $screen-md-min) {
				left: 0;
				width: 65%;
				top: 50%;
				-webkit-transform: translate(0,-50%);
				-ms-transform: translate(0,-50%);
				transform: translate(0,-50%);
				right: 0;

				h1 {
					font-size: 46px;
					font-weight: 500; }
				h2 {
					font-weight: 400; }
				h3 {
					font-weight: 500;
					margin-bottom: 5px;
					margin-top: 0; } }

			@media (min-width: $screen-lg-min) {

				h1 {
					font-size: 60px; } } }

		.btn-primary {
			float: right;
			clear: both;

			&.btn-big {
				font-size: 20px;

				@media (max-width: 1000px) {
					font-size: 15px; } }

			@media (min-width: 630px) {
				float: left; }

			@media (min-width: $screen-sm-min) {
				margin-top: 10px;
				font-size: 24px;
				float: none; } } }

	.carousel-indicators {
		height: 20px;

		li {
			background-color: $dark-grey;
			border-color: $dark-grey;
			position: relative;
			margin: 0 3px;
			width: 12px;
			height: 12px; }

		.active {
			&:after {
				content: '';
				border: 2px solid $dark-grey;
				display: block;
				width: 23px;
				height: 23px;
				background-color: transparent;
				border-radius: 23px;
				position: absolute;
				top: 50%;
				left: 50%;
				bottom: 0;
				-webkit-transform: translate(-50%,-50%);
				-ms-transform: translate(-50%,-50%);
				transform: translate(-50%,-50%); } } } }

.carousel-control {
	z-index: 99;

	.chevron-left,
	.chevron-right {
		display: block;
		width: 35px;
		height: 68px;
		background-image: url('../img/chevrons.png');
		background-repeat: no-repeat;
		text-indent: -9999px;
		top: 50%;
		-webkit-transform: translate(0,-50%);
		-ms-transform: translate(0,-50%);
		transform: translate(0,-50%);
		position: absolute; }

	.chevron-left {
		background-position: 0 0;
		left: 10%; }

	.chevron-right {
		background-position: 0 -68px;
		right: 10%; } }

.carousel-inner > .item {
	height: 300px;

	@media (min-width: $screen-sm-min) {
		height: inherit; }

	@media (max-width: $screen-sm-min - 1) {
		> img {
			height: 100%;
			width: auto;
			max-width: inherit; } } }


#carousel-area-cardio {
	.carousel-inner {
		.carousel-caption {
			&:after {
				content: '';
				background-image: url('../img/logo-areacardio.png');
				background-repeat: no-repeat;
				background-position: 0 0;
				width: 146px;
				height: 146px;
				position: absolute;
				left: -50%;
				top: 50%;
				-webkit-transform: translate(-50%,-50%);
				-moz-transform: translate(-50%,-50%);
				-ms-transform: translate(-50%,-50%);
				transform: translate(-50%,-50%);
				display: none;

				@media (min-width: 480px) {
					display: block; }

				// @media (min-width: 630px)
				// 	left: -300px

				// @media (min-width: $screen-sm-min)
				// 	top: 40px

				// @media (min-width: $screen-md-min)
				// 	top: 150px
				// 	left: -220px

				// @media (min-width: $screen-lg-min)
				// 	top: 165px
 } } } }				// 	left: -260px



