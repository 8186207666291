.thumbnail {
	border: none;
	border-radius: 0;
	background-color: transparent;
	line-height: inherit;
	margin-top: 20px;

	.caption-filosofia {
		color: $white;
		font-size: $font-size-base * 0.8; } }
