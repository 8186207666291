
h5.bg-primary,
h6.bg-info {
	margin: 0;
	padding-left: 15px;
	padding-right: 15px; }

h6.bg-info {
	padding-bottom: 20px;
	padding-top: 20px; }

p.bg-info {
	margin-left: -15px;
	margin-right: -15px;
	padding: 15px; }
