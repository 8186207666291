//*--- 	Mixin Fonts ---*
@mixin fonts($font) {
	@font-face {
		font-family: $font;
		src: url('../fonts/#{$font}.eot');
		src: url('../fonts/#{$font}.eot?#iefix') format('embedded-opentype'),
		url('../fonts/#{$font}.woff') format('woff'),
		url('../fonts/#{$font}.ttf') format('truetype'),
		url('../fonts/#{$font}.svg#$font') format('svg') {} } }

@include fonts('brandon_med');
@include fonts('brandon_reg');
@include fonts('brandon_blk');

html {
	position: relative;
	min-height: 100%; }

body {
	margin-bottom: 350px;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
	color: $font-base-color;

	@media (min-width: $screen-sm-min) {
		margin-bottom: $footer-height + $subfooter-height; } }

input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0px 1000px $white inset; }

input[type='number'] {
	-moz-appearance: textfield; }

.form-control::-webkit-outer-spin-button,
.form-control::-webkit-inner-spin-button {
	-webkit-appearance: none; }

ul, ol {
	padding: 0;
	list-style: none; }

//li
//	background-image: url('../img/icon-list.png')
//	background-repeat: no-repeat
//	background-position: 0 7px


ul.row {
	margin-bottom: 0; }

ul.logos-container {
	margin-top: 25px;
	margin-bottom: 25px;

	li {
		display: inline-block;
		margin-left: 15px;
		margin-right: 15px;

		img {
			margin: 0 auto; } } }

.intro {
	padding-top: 25px;
	padding-bottom: 25px; }

.bg-primary {
	background-color: $dark-grey; }

.bg-info {
	background-color: $light-grey; }

.bg-grey {
	background-color: $very-light-grey; }

.cursos-container {
	overflow: hidden;

	.col-sm-6 {
		margin: 15px 0; } }

.diamond {
	width: 10px;
	height: 10px;
	background-color: $red;
	border-radius: 2px;
	transform: rotate(45deg);
	display: block;
	margin: 0 auto; }

.block-container {
	margin-top: 30px;
	margin-bottom: 30px;

	.panel {
		margin-bottom: 15px;
		&:last-child {
			margin-bottom: 0; } } }

.servicios + .servicios {
	margin-bottom: 35px; }

.empresas {
	background-color: $red;
	color: $white;

	img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		max-width: 85%; } }

.areaprotegida {
	background-image: url('../img/empresas-areaprotegida-mobile.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;

	@media (min-width: $screen-md-min) {
		background-image: url('../img/empresas-areaprotegida-web.jpg'); } }

.areacardio {
	background-image: url('../img/empresas-areacardio-mobile.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;

	@media (min-width: $screen-md-min) {
		background-image: url('../img/empresas-areacardio-web.png'); } }

.medicinalaboral {
	background-image: url('../img/empresas-medicinalaboral-mobile.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;

	@media (min-width: $screen-md-min) {
		background-image: url('../img/empresas-medicinalaboral-web.png'); } }

.eventos {
	background-image: url('../img/empresas-eventos-mobile.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;

	@media (min-width: $screen-md-min) {
		background-image: url('../img/empresas-eventos-web.png'); } }

.planes {
	background-image: url('../img/empresas-planes-mobile.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;

	@media (min-width: $screen-md-min) {
		background-image: url('../img/empresas-planes-web.png'); } }

.serviciomedico {
	background-image: url('../img/empresas-serviciomedico-mobile.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;

	@media (min-width: $screen-md-min) {
		background-image: url('../img/empresas-serviciomedico-web.png'); } }

.cursos {
	background-image: url('../img/empresas-cursos-mobile.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;

	@media (min-width: $screen-md-min) {
		background-image: url('../img/empresas-cursos-web.png'); } }

