.panel {
	border: 0;
	border-radius: 0;
	box-shadow: none;
	margin-bottom: 0; }

.panel-info {
	border-color: transparent;
	background-color: $dark-grey;
	padding: 30px 0;
	color: $white;

	h2 {
		color: $white;
		margin-bottom: 30px; }

	&.panel-complementarios {
		.item-7 {
			width: 14%;

			@media (max-width: $screen-md-min) {
				width: 25%; }

			@media (max-width: $screen-sm-min) {
				width: 50%; } } } }
.panel-primary {
	border-color: transparent;
	background-color: $light-grey;
	padding: 30px 0; }

.empresa-text {
	position: absolute;
	color: $white;
	padding: 10px;
	margin-top: 35%;
	left: 0;
	right: 0;
	a {
		color: $white;
		&:hover, &:link, &:visited, &:active {
			color: $white;
			text-decoration: none; } }
	@media (max-width: $screen-sm-min) {
		margin-top: 10%; }

	@media (min-width: 1400px) {
		margin-top: 30%; } }


.know {
	&:before {
		content: url('../img/plus-icon.png');
		position: absolute;
		margin-left: -25px; } }
.panel-empresas {
	display: block;
	height: 100%;
	min-height: 270px;
	position: relative;
	overflow: hidden;
	&:hover .caption {
		min-height: 100%;
		transition: all 0.5s ease;
		-webkit-transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease; }

	&:hover > .caption span {
		top: 10%; }
	&:hover > .caption:after {
		top: 10px;
		transform: rotate(-270deg) translate(100%,0);
		-webkit-transform: rotate(-270deg) translate(100%,0);
		-ms-transform: rotate(-270deg) translate(100%,0);
		@media (min-width: 1400px) {
			top: 15px; } }


	@media (min-width: $screen-sm-min) {
		max-width: 480px; }

	@media (min-width: $screen-lg-min) {
		min-height: 380px; }

	@media (min-width: 1400px) {
		min-height: 420px; } }

.caption {
	background-color: rgba(50,50,50,0.8);
	border-top: 4px solid $red;
	color: $white;
	bottom: 0;
	left: 0;
	position: absolute;
	width: 100%;
	text-align: right;
	padding: 10px 40px 10px 0;
	min-height: 50px;
	font-weight: 600;
	line-height: 1;

	&:after {
		content: '';
		display: block;
		width: 14px;
		height: 24px;
		background-image: url('../img/chevron-transparent.png');
		background-position: 0 0;
		background-repeat: no-repeat;
		transform: rotate(-90deg) translate(100%,0);
		-webkit-transform: rotate(-90deg) translate(100%,0);
		-ms-transform: rotate(-90deg) translate(100%,0);
		position: absolute;
		right: 15px;
		top: 50%; }

	span {
		position: absolute;
		top: 45%;
		right: 40px;
		transform: translate(0, -50%); } }



.caption-small {
	background-color: rgba(50,50,50,0.8);
	border-top: 4px solid $red;
	color: $white;
	bottom: 0;
	left: 0;
	position: absolute;
	width: 100%;
	text-align: right;
	padding: 10px 40px 10px 0;
	min-height: 50px;
	font-weight: 600;
	line-height: 1;

	&:after {
		content: '';
		display: block;
		width: 14px;
		height: 24px;
		background-image: url('../img/chevron-transparent.png');
		background-position: 0 0;
		background-repeat: no-repeat;
		transform: rotate(-90deg) translate(100%,0);
		-webkit-transform: rotate(-90deg) translate(100%,0);
		-ms-transform: rotate(-90deg) translate(100%,0);
		position: absolute;
		right: 15px;
		top: 50%; }

	span {
		position: absolute;
		top: 45%;
		right: 40px;
		transform: translate(0, -50%); } }

.panel-grey {
	border-color: transparent;
	background-color: $dark-grey;
	padding: 5px 0;
	color: $white;

	h3 {
		color: $red;
		margin: 10px 0 15px 0;
		font-size: 16px; } }

/* panel noticias / novedades */
.panel-noticias {
	display: block;
	height: 100%;
	min-height: 270px;
	position: relative;
	overflow: hidden;
	background-size: cover;
	background-position: center center;
	//cursor: pointer

	&:hover .caption {
		min-height: 100%;
		transition: all 0.5s ease;
		-webkit-transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease; }

	&:hover {
		.caption {
			.desc-small {
				display: none; } }
		.noticia-text {
			display: block !important; } }

	&:hover > .caption:after {
		top: 0;
		transform: rotate(-270deg) translate(100%,0);
		-webkit-transform: rotate(-270deg) translate(100%,0);
		-ms-transform: rotate(-270deg) translate(100%,0); }

	@media (min-width: $screen-lg-min) {
		min-height: 280px; }

	@media (min-width: 1400px) {
		min-height: 280px; }

	.caption {
		background-color: rgba(#5e5e5e,0.99);
		border-top: 4px solid $red;
		color: $white;
		bottom: 0;
		left: 0;
		position: absolute;
		width: 100%;
		text-align: right;
		padding: 10px 40px 10px 10px;
		min-height: 50px;
		height: 105px;
		font-weight: 600;
		line-height: 1;

		&:after {
			content: '';
			display: block;
			width: 14px;
			height: 24px;
			background-image: url('../img/chevron-transparent.png');
			background-position: 0 0;
			background-repeat: no-repeat;
			transform: rotate(-90deg) translate(100%,0);
			-webkit-transform: rotate(-90deg) translate(100%,0);
			-ms-transform: rotate(-90deg) translate(100%,0);
			position: absolute;
			right: 15px;
			top: 20px; }

		h4 {
			font-size: 16px;
			margin: 5px 0; }

		span {
			font-size: 12px;
			position: relative;
			right: 0; }

		.descripcion {
			font-size: 12px;
			font-weight: normal;
			text-align: right !important;
			max-height: 25px;
			overflow: hidden;

			p, h1, h2, h3, h4, h5, h6 {
				font-size: 12px;
				font-weight: normal;
				text-align: right !important; } }

		.sub-titulo {
			font-size: 11px;
			font-weight: normal;
			margin: 5px 0;
			//max-height: 12px
 } }			//overflow: hidden

	.caption-small {
		background-color: rgba(#5e5e5e, 0.99);
		border-top: 4px solid $red;
		color: $white;
		bottom: 0;
		left: 0;
		position: absolute;
		width: 100%;
		text-align: right;
		padding: 0 40px 0 10px;
		min-height: 50px;
		height: 89px;
		font-weight: 600;
		line-height: 1;

		&:after {
			content: '';
			display: block;
			width: 14px;
			height: 24px;
			background-image: url('../img/chevron-transparent.png');
			background-position: 0 0;
			background-repeat: no-repeat;
			transform: rotate(-90deg) translate(100%,0);
			-webkit-transform: rotate(-90deg) translate(100%,0);
			-ms-transform: rotate(-90deg) translate(100%,0);
			position: absolute;
			right: 15px;
			top: 50%; }

		span {
			position: absolute;
			top: 45%;
			right: 40px;
			transform: translate(0, -50%); } }

	.panel-grey {
		border-color: transparent;
		background-color: $dark-grey;
		padding: 5px 0;
		color: $white;

		h3 {
			color: $red;
			margin: 10px 0 15px 0;
			font-size: 16px; } }

	.noticia-text {
		position: absolute;
		color: $white;
		padding: 10px;
		left: 0;
		right: 0;
		a {
			color: $white;
			&:hover, &:link, &:visited, &:active {
				color: $white;
				text-decoration: none; } }
		@media (max-width: $screen-sm-min) {
 }			//margin-top: 10%

		@media (min-width: 1400px) {
 } } }			//margin-top: 30%
