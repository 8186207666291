form {
	h3 {
		padding-bottom: 30px; } }

.form-control {
	border: none;
	box-shadow: none;
	border-radius: 0;
	line-height: 2; }

label {
	margin-bottom: 0px;
	line-height: 1; }

.optional:after {
	content: '(Opcional)';
	color: #666;
	padding-left: 5px; }

.required:after {
	font-size: 16px;
	content: '*';
	color: red;
	padding-left: 5px; }

.help-block {
	margin-top: 0px; }
