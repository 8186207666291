body {
	font-family: 'Raleway', sans-serif;
	line-height: 2;
	font-size: 13px; }

.intro {

	h2 {
		margin-bottom: 45px; }

	p {
		margin-bottom: 35px; } }

.lead {
	font-size: $font-size-base + 1px;
	font-weight: 500; }
