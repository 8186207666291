.table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > th,
.table > tfoot > tr > td {
	border-top: none;
	border-bottom: none;
	border-left: none;
	border-right: 1px solid $light-grey; }


.table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > th,
.table > tfoot > tr > td {
	font-size: $font-size-base - 3; }

th {
	background-color: $red;
	color: $white;
	&:first-child {
		background-color: darken($red, 15%); } }

.table-striped {
	> tbody {
		> tr {
			background-color: darken($light-grey, 15%);
			color: $white;
			border-color: $grey;

			td:first-child {
				background-color: $dark-grey; } }

		> tr:nth-of-type(odd) {
			td {
				background-color: darken($light-grey, 30%); }

			td:first-child {
				background-color: darken($dark-grey, 15%); } } } }
