//Colors

$red: rgb(247,0,26); 				// #f7001a
$white: rgb(255,255,255);			// #ffffff
$very-light-grey: rgb(247,249,251);  // #f7f9fb
$light-grey: rgb(225,225,225);		// #e1e1e1
$grey: rgb(124,124,124);				// #7c7c7c
$dark-grey: rgb(54,54,54);			// #363636
$black: rgb(0,0,0);					// #000000

.red {
	color: $red; }

.white {
	color: $white; }

.very-light-grey {
	color: $very-light-grey; }

.light-grey {
	color: $light-grey; }

.grey {
	color: $grey; }

.dark-grey {
	color: $dark-grey; }

.black {
	color: $black; }



$font-base-color: $dark-grey;
$link-color: $red;
$link-hover-color: darken($link-color, 15%);

$bg-nabvar: $white;
$bg-grey: $very-light-grey;

$footer-link-color: $white;


//Size variables
$navbar-min-height: 105px;
$footer-height: 200px;
$subfooter-height: 75px;


// Buttons variables
$btn-primary-color: $white;
$btn-primary-bg: $dark-grey;
$btn-primary-border: $dark-grey;

$btn-success-color: $white;
$btn-success-bg: $red;
$btn-success-border: $red;
