/* landings */

.landing {
	width: 100%;

	.portada {
		img {
			width: 100%; }

		.img {
			background-size: cover;
			background-repeat: no-repeat;
			padding-top: 30%; } }

	.form {

		form {
			input, textarea {
				border: 1px solid #e1e1e1 !important; } } }

	.block-white {
		float: left;
		width: 100%;
		background: #ffffff;
		color: #666666;
		padding: 30px 10px;

		h1 {
			border-bottom: 1px solid #666666;
			font-size: 25px;
			padding-bottom: 10px;
			margin: 0 0 10px 0;
			font-family: "brandon_med"; }

		p {
			font-size: 18px;
			margin: 0;
			line-height: 22px;
			font-family: "brandon_med"; } }

	.block-gris {
		float: left;
		width: 100%;
		background: #363636;
		color: #fff;
		padding: 30px 10px;

		h1 {
			border-bottom: 1px solid #fff;
			font-size: 25px;
			padding-bottom: 10px;
			margin: 0 0 10px 0;
			font-family: "brandon_med"; }

		p {
			font-size: 18px;
			margin: 0;
			line-height: 22px;
			font-family: "brandon_med"; } }

	.block-gris-claro {
		float: left;
		width: 100%;
		background: #e1e1e1;
		padding-top: 30px;

		h4 {
			color: #f7001a;
			font-size: 20px; } } }
